
jQuery(document).ready(function ($) {
    /*start slider*/

    $('.js-main-bg-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        speed: 1500,
        fade: true,
        cssEase: 'linear',
        asNavFor: '.js-main-slider',
		autoplay: true,
		autoplaySpeed: 9000,
    });
    $('.js-main-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        asNavFor: '.js-main-bg-slider',
        arrows: false,
        dots: true,
        fade: true,
        speed: 1500,
		autoplay: true,
		autoplaySpeed: 9000,
    });
    /*finish slider*/
    /*start formstyler*/
    $('input[type="checkbox"], input[type="radio"],select').styler();
    /*finish formstyler*/
    /*photo and video slider */
    $('#gallery_photo_carousel').slick({
        slidesToShow: 3,
        responsive: [
            {
                breakpoint: 960,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });
    $('#gallery_video_carousel').slick({
        adaptiveHeight: false
    });
    /*/photo and video slider */
    /*if ($(window).width() > 769) {
     var footerHeight = jQuery('footer').outerHeight();
     $('aside').css('padding-bottom',footerHeight);
     }
     $(window).resize(function () {
     if ($(window).width() > 769) {
     var footerHeight = jQuery('footer').outerHeight();
     $('aside').css('padding-bottom',footerHeight);
     }
     });*/

    function mainSlider(){
        var height_w = $(window).height(),
            height_floatck_fixed = height_w - 85,
            height_floatck = height_w - 167,
            footerHeight = jQuery('footer').outerHeight(),
            minMainHeight = height_w - 85 - footerHeight;
        $('.index-page .container-product-menu div.floatck').css('height', height_floatck);
        $('.header-fixed .container-product-menu div.floatck').css('height', height_floatck_fixed);
        $('.index-page .container-product-menu-overflow').css('height', height_floatck);
        $('.header-fixed .container-product-menu-overflow').css('height', height_floatck_fixed);
        $('.uk-container').css('min-height', minMainHeight);
    }
    mainSlider();
    $(window).resize(function () {
        mainSlider();
    });
    /*start finish main-slider-mobile*/
    /*open login*/
    $('.js-login').on('click', function () {
        $(this).addClass('active');
        $('.js-login-form').fadeIn();
    });
    $('.js-close-form').on('click', function () {
        $('.js-login').removeClass('active');
        $('.js-login-form').fadeOut();
    });
    /*finish login*/
    /*start open top menu*/
    /*function topMenuOpen(){
     $('.js-humburger').click(function () {
     var obj = $('.header-fixed .header-center');
     if (obj.hasClass('active')) {
     obj.removeClass('active').fadeOut();
     $('.js-humburger').removeClass('on');
     $('.header-fixed').animate({height:'85px'},500);
     $('.header-fixed .header').animate({top:'0px'},500);
     $('.header-fixed .container-product-menu').animate({top:'0px'},500);

     } else {
     obj.addClass('active').fadeIn();
     $('.header-fixed').animate({height:'117px'},500);
     $('.header-fixed .header').animate({top:'32px'},500);
     $('.header-fixed .container-product-menu').animate({top:'32px'},500);
     $('.js-humburger').addClass('on');
     }
     });
     }*/

    if ($(window).width() > 1024) {
        $('.js-humburger').click(function () {
            var obj = $('.header-fixed .header-center');
            if (obj.hasClass('active')) {
                obj.removeClass('active').fadeOut();
                $('.js-humburger').removeClass('on');
                $('.header-fixed').animate({height: '85px'}, 500);
                $('.header-fixed .header').animate({top: '0px'}, 500);
                $('.header-fixed .container-product-menu').animate({top: '0px'}, 500);

            } else {
                obj.addClass('active').fadeIn();
                $('.header-fixed').animate({height: '117px'}, 500);
                $('.header-fixed .header').animate({top: '32px'}, 500);
                $('.header-fixed .container-product-menu').animate({top: '32px'}, 500);
                $('.js-humburger').addClass('on');
            }
        });
    } else {

    }

    if ($(window).width() < 1024) {
        $('.header-fixed').css('height', '62px');
    }
	else{
		 $('.header-fixed').css('height', '85px');
	}
    $(window).resize(function () {
        if ($(window).width() < 1024) {
			$('.header-fixed').css('height', '62px');
		}
		else{
			 $('.header-fixed').css('height', '85px');
		}
    });
    /*select radiobox on gallery page*/
    $('.gallery_radio_button .jq-radio').each(function () {
        if ($(this).parents().hasClass('all')) {
            $(this).addClass('checked');
        }
    });
    $('.gallery_radio_button label').click(function () {
        $('.gallery_radio_button label').find('.jq-radio').removeClass('checked');
        $('.gallery_links_item').removeClass('active');
        $('.gallery_links_item').find('.gallery_links_item_img').attr('src', '/images/images/icon_photo_album.png');
        if ($(this).hasClass('photo')) {
            $(this).find('.jq-radio').addClass('checked');
            $('.js_video').hide();
            $('.js_photo').show();
        } else if ($(this).hasClass('video')) {
            $(this).find('.jq-radio').addClass('checked');
            $('.js_photo').hide();
            $('.js_video').show();
        } else {
            $(this).find('.jq-radio').addClass('checked');
            $('.js_photo').show();
            $('.js_video').show();
        }
    });
    /*/select radiobox on gallery page*/
    /* click gallery_links_item*/
    $('.gallery_links_item').click(function () {
        $('.gallery_links_item').removeClass('active');
        $('.gallery_radio_button label').find('.jq-radio').removeClass('checked');
        $(this).addClass('active');
        $(this).find('.gallery_links_item_img').attr('src', '/images/images/icon_photo_album_active.png');
        $('.js_video').hide();
        $('.gallery_section_photo_carousel.js_photo').hide();

    });
    /* /click gallery_links_item*/
    /*add ... */
    $(document).ready(function () {
        $('.info_content, .blog-item-descr, .news-item-descr').each(function () {
            var content = $(this).html();
            var value = content.split('');
            if (value.length >= 249) {
                var str = content.slice(0, 249);
                $(this).html(str + '...');
            }
        })
    });
    /*/add ... */
    /*accordion*/
    $(document).ready(function () {
        $('.events-year').not('.active').find('.events-year-wrap').slideUp();
        $('.events-year h2').click(function () {
            if ($(this).parents('.events-year').hasClass('active')) {
                $(this).siblings('.events-year-wrap').slideUp();
                $(this).parents('.events-year').removeClass('active');
            } else {
                $(this).siblings('.events-year-wrap').slideDown();
                $(this).parents('.events-year').addClass('active');
            }
        });
    });
    $(document).ready(function () {
        if (window.location.hash) {
            var anchor = window.location.hash.slice(1);
            var anchorplace = $('.events-year-wrap .events-items').find('#' + anchor);
            var scroll = anchorplace.offset().top - 90;
            $('html, body').stop().animate({scrollTop: scroll}, 200);
        }
    });
    /*/accordion*/
    /*news and releases*/
    $('.info_section.column_2').masonry({
        // options
        itemSelector: '.info_item',
        gutter: 20

    });
    $('.info_section.column_3').masonry({
        // options
        itemSelector: '.info_item',
        gutter: 20
    });
    /*/news and releases*/

    $.validator.addMethod('validate_name', function (value, element) {
        return this.optional(element) || /^[a-zA-Zа-яёА-ЯЁ\s\-]+$/.test(value);
    }, 'Please enter your name correctly');
    var form = $('#cust_info_form_id'),
            valid = form.validate({
                rules: {
                    cname: {
                        required: true,
                        validate_name: true,
                        minlength: 2
                    },
                    cemail: {
                        required: true,
                        email: true
                    },
                    ccompany: 'required'
                },
                messages: {
                    cname: {
                        required: 'Please enter your name',
                        minlength: 'Sorry! Don`t think you`ve got such a short name!)'
                    },
                    cemail: {
                        required: 'Please enter your mail',
                        email: 'Please check your e-mail'
                    },
                    ccompany: 'Please enter your Company name'
                }
            });

    $('.js-custom-request-button').on('click', function () {
        form.submit();
    });

    $('.js-subscribe-button').on('click', function () {
        $('.acymailing_lists .acymailing_checkbox').parent().removeClass('error');
        formSubscr.find('input').removeClass('error');
        $('.js-subscription-form form')[0].reset();
        $('.overlay,.pop-up,.js-subscription-form').fadeIn();
    });


    /*contact form*/
    $('.js-contact-button').on('click', function () {
        formContact.find('input').removeClass('error');
        formContact.validate().resetForm();
        $('.js-contact-form form')[0].reset();
        $('.overlay,.pop-up,.js-contact-form').fadeIn();
    });

    $('.js-close-popup,.overlay').on('click', function () {
        $('.overlay,.pop-up,.js-contact-form,.js-subscription-form').fadeOut();
    });
    var formContact = $('#contact-form'),
            valid = formContact.validate({
                rules: {
                    contactname: {
                        required: true,
                        validate_name: true,
                        minlength: 2
                    },
                    contactemail: {
                        required: true,
                        email: true
                    }
                },
                messages: {
                    contactname: {
                        required: 'Please enter your name',
                        minlength: 'Sorry! Don`t think you`ve got such a short name!)'
                    },
                    contactcemail: {
                        required: 'Please enter your mail',
                        email: 'Please check your e-mail'
                    }
                }
            });
    $('.js-contact-form-button').on('click', function () {
        formContact.submit();
    });
    var formContactUs = $('#contact-us-form'),
            valid = formContactUs.validate({
                rules: {
                    contactusname: {
                        required: true,
                        validate_name: true,
                        minlength: 2
                    },
                    contactusemail: {
                        required: true,
                        email: true
                    }
                },
                messages: {
                    contactusname: {
                        required: 'Please enter your name',
                        minlength: 'Sorry! Don`t think you`ve got such a short name!)'
                    },
                    contactuscemail: {
                        required: 'Please enter your mail',
                        email: 'Please check your e-mail'
                    }
                }
            });
    $('.js-contact-us-form-button').on('click', function () {
        formContactUs.submit();
    });
    /*subscribe*/
    var formSubscr = $('.acymailing_fulldiv form'),
            valid = formSubscr.validate({
                debug: true,
                errorElement: "label",
                errorPlacement: function (error, element) {
                    var type = $(element).attr("type");
                    if (type === "checkbox") {
                        // custom placement
                        error.insertAfter(element.parent().parent().parent().parent().parent().parent());
                    } else {
                        error.insertAfter(element);
                    }
                },
                rules: {
                    "user[email]": {
                        email: true,
                        required: true
                    },
                    "subscription[]": {
                        required: true
                    }
                },
                messages: {
                    "user[email]": {
                        required: 'Please enter your mail',
                        email: 'Please check your e-mail'
                    },
                    "subscription[]": {
                        required: 'Please check one of subcribes'

                    }
                }
            });
    $('#submit-subscribe').on('click', function () {
        $(".acymailing_lists input:checkbox").each(function () {
            if ($('.acymailing_lists .acymailing_checkbox').hasClass('checked')) {
                $(this).parent().removeClass('error');
            } else {
                $(this).parent().addClass('error');

            }
        });
        formSubscr.validate().form();
        if (!formSubscr.validate().form()) {
            return false
        } else {
            formSubscr.submit();
        }

    });

    /*placeholder for input*/
// $('.cust_info_form .inputbox').focus(function () {
// 	if(!($(this).attr('value') == '')) {
// 		$(this).attr('value', '');
// 	}
// });
// $('.cust_info_form .inputbox').blur(function () {
// 	if($(this).attr('value') == '') {
// 		$(this).attr('value', $(this).attr('title'));
// 	}
// });
    /*/placeholder for input*/
    /*finish open top menu*/
    /*start open search*/

    function search() {
        $('.header-fixed .js-search-icon').on('click', function () {
            $('.header-fixed .header-right').hide();
            $('.header-fixed .container-product-menu').hide();
            $('.header-fixed .js-header-search').css('top', '0').fadeIn();
            if ($('.header-center').hasClass('active')) {
                $('.header-fixed').css('height', '117px');
            } else {
                $('.header-fixed').css('height', '85px');
            }
        });
        $('.js-close-button').on('click', function () {
            $('.header-fixed .js-header-search').hide();
            $('.header-fixed .container-product-menu').show();
            $('.header-fixed .header-right').show();
            $('.header-fixed').css('height', '85px');
        });
        $('header .header .js-search-icon').on('click', function () {
            $('header.shown .header .header-right').hide();
            $('header.shown .header .container-product-menu').hide();
            $('header.shown .header .header-center').hide();
            $('header.shown .header .js-header-search').css('top', '0').fadeIn();
            $('header.shown .header').css('height', '85px');
        });
        $('.js-close-button').on('click', function () {
            $('header.shown .header .js-header-search').hide();
            $('header.shown .header').css('height', '0px');
            $('header.shown .header .header-center').show();
            $('header.shown .header .container-product-menu').show();
            $('header.shown .header .header-right').show();
        });
    };
    function mobileSearch() {
        $('.header-fixed .js-search-icon').on('click', function () {
            $('.header-fixed .header-right').hide();
            $('.header-fixed .header-logo').hide();
            $('.header-fixed .js-header-search').css('top', '0').fadeIn();
            if ($('.header-center').hasClass('active')) {
                $('.header-fixed').css('height', '117px');
            } else {
                $('.header-fixed').css('height', '61px');
            }
        });
        $('.js-close-button').on('click', function () {
            $('.header-fixed .js-header-search').hide();
            $('.header-fixed .header-right').show();
            $('.header-fixed .header-logo').show();
            $('.header-fixed').css('height', '61px');
        });
    };
    function index() {
        $('header').removeClass('hidden').addClass('shown');
        $('.container-product-menu').removeClass('hidden').addClass('shown');
        $('header.header-fixed').removeClass('shown').addClass('hidden');
    };
    function indexMobile() {
        $('header').removeClass('shown').addClass('hidden');
        $('.container-product-menu').removeClass('shown').addClass('hidden');
        $('header.header-fixed').removeClass('hidden').addClass('shown');
    };
    function indexScroll() {
        var tempScrollTop = 0,
            scrollingTop = 0;
       $(window).scroll(function () {
            var height_w = $('.container-first').height(),
                height_floatck_fixed = height_w - 85,
                height_w_85 = height_w + 85,
                height_floatck_halffixed = height_floatck_fixed - 85,
                scrollingTop = parseInt($(window).scrollTop());
                ('height_floatck_fixed '+height_floatck_fixed);

            if(scrollingTop > height_w_85){
                $('.index-page header.header-fixed .header-center').removeClass('active').fadeOut();
                $('.index-page .js-humburger').removeClass('on');
                $('.index-page header.header-fixed').css('height','85px');
                $('.index-page header.header-fixed .header').css('top','0px');
                $('.index-page header.header-fixed .container-product-menu').css('top','0px');
                $('.index-page header').removeClass('shown shown-half hidden-half').addClass('hidden');
                $('.index-page .container-product-menu').removeClass('shown shown-half hidden-half').addClass('hidden');
                $('.index-page header.header-fixed').removeClass('hidden shown-half hidden-half').addClass('shown');
                $('.index-page .header-fixed .container-product-menu').removeClass('hidden shown-half hidden-half').addClass('shown');

            }else if (scrollingTop > height_floatck_fixed && tempScrollTop > scrollingTop) {
                $('.index-page header.header-fixed .header-center').removeClass('active').fadeOut();
                $('.index-page .js-humburger').removeClass('on');
                $('.index-page header.header-fixed').css('height','85px');
                $('.index-page header.header-fixed .header').css('top','0px');
                $('.index-page header.header-fixed .container-product-menu').css('top','0px');
                $('.index-page header').removeClass('shown shown-half hidden-half').addClass('hidden');
                $('.index-page .container-product-menu').removeClass('shown shown-half hidden-half').addClass('hidden');
                $('.index-page header.header-fixed').removeClass('hidden shown-half hidden-half').addClass('shown');
                $('.index-page .header-fixed .container-product-menu').removeClass('hidden shown-half hidden-half').addClass('shown');
            }else if (scrollingTop > height_floatck_fixed) {
                $('.index-page header.header-fixed .header-center').addClass('active').fadeIn();
                $('.index-page header.header-fixed').css('height','117px');
                $('.index-page header.header-fixed .header').css('top','32px');
                $('.index-page header.header-fixed .container-product-menu').css('top','32px');
                $('.index-page .js-humburger').addClass('on');
                $('.index-page header').removeClass('shown shown-half hidden-half').addClass('hidden');
                $('.index-page .container-product-menu').removeClass('shown shown-half hidden-half').addClass('hidden');
                $('.index-page header.header-fixed').removeClass('hidden shown-half hidden-half').addClass('shown');
                $('.index-page .header-fixed .container-product-menu').removeClass('hidden shown-half hidden-half').addClass('shown');

            }else if(scrollingTop > height_floatck_halffixed){
                $('.index-page .header-fixed .container-product-menu').removeClass('shown').addClass('hidden hidden-half');
                $('.index-page header').removeClass('hidden shown-half hidden-half').addClass('shown shown-half');
                $('.index-page header.header-fixed').removeClass('shown shown-half hidden-half').addClass('hidden hidden-half');
                $('.index-page .container-product-menu').removeClass('hidden shown-half hidden-half').addClass('shown shown-half');

            }else {
                $('.index-page .header-fixed .container-product-menu').removeClass('shown').addClass('hidden');
                $('.index-page header').removeClass('hidden shown-half hidden-half').addClass('shown');
                $('.index-page header.header-fixed').removeClass('shown shown-half hidden-half').addClass('hidden');
                $('.index-page .container-product-menu').removeClass('hidden shown-half hidden-half').addClass('shown');

            }
			tempScrollTop = scrollingTop;
        });
    };





    if ($(window).width() > 1024) {
        search();
        if ($('body').hasClass('index-page')) {
            index();
            indexScroll();
        } else {
            indexScroll();
        }
    } else {
        indexMobile();
        $(window).scroll(function () {
            indexMobile();
        });
        mobileSearch();
    }
    $(window).resize(function () {
        if ($(window).width() > 1024) {
            search();
            if ($('body').hasClass('index-page')) {
                index();
                indexScroll();
            } else {
                indexScroll();
            }
        } else {
            indexMobile();
            $(window).scroll(function () {
                indexMobile();
            });
            mobileSearch();
        }
    });
    /*start mobile menu*/

    /*finish mobile menu*/
    /*start height mobile start block*/
    function mobileHeight() {
        var heightDev = $(window).height(),
                heightMob = heightDev - 61;
        $('.main-slider-mobile').css('height', heightMob);
        $('.header-mobile-menu').css('max-height', heightMob);
    }
    mobileHeight();
    $(window).resize(function () {
        mobileHeight();
    });
    /*finish height mobile start block*/
    /*start scroll down mobile*/
    $('.main-slider-mobile-down').click(function () {
        $('html, body').animate({scrollTop: $('#services').offset().top}, 'slow');
    });
    function mobileStart() {
        var obj = $('.main-slider-mobile-wrap ul li'),
                qitems = obj.length;
        if (qitems == 4) {
            obj.find('a').css('height', '22vh');
            obj.find('p').css('padding', '11vh 2vw 2vh').css('background-position', 'center 3vh').css('background-size', 'auto 8vh');

        } else if (qitems >= 5) {
            obj.find('a').css('height', '18vh');
            obj.find('p').css('font-size', '14px').css('padding', '12vh 2vw 2vh').css('background-position', 'center 3vh').css('background-size', 'auto 8vh');
        }
    }
    mobileStart();
    $('textarea').scrollbar({		'disableBodyScroll': true	});	$('.header-mobile-menu-wrap').scrollbar({		'disableBodyScroll': true,		'onInit': function(){		var height_w = $(window).outerHeight(),			height_mobile_menu = height_w - 61;			$('.scroll-wrapper.header-mobile-menu-wrap,.header-mobile-menu-wrap.scroll-content').css('max-height',height_mobile_menu);	}});

    $('.header-mobile-menu-humburger').click(function () {
        $('.mobilebarmaximenuck').trigger('click');
        if ($(this).hasClass('active')) {
            $(this).removeClass('active');
            $(this).removeClass('on');
        } else {

            $(this).addClass('active');
            $(this).addClass('on');
        }
    });

    /*finish scroll down mobile*/
    jQuery('.download-block div a').click(function () {
        var ul = jQuery(this).parent().find('ul');
        if (ul.is(':hidden')) {
            jQuery(this).addClass('strong');
            ul.slideDown();
        } else {
            jQuery(this).removeClass('strong');
            ul.slideUp();
        }
    });
    $('.container-product-menu .maxidrop-main2').click(function () {

        if ($('.container-product-menu-overflow').hasClass('shown')) {
            $('.container-product-menu-overflow').removeClass('shown');
            $(this).parents('li.maximenuck').trigger('mouseout', '300');
        } else {

        }
    });
    $('.container-product-menu li.maximenuck.level1').hover(function () {
        if ($('.container-product-menu-overflow').hasClass('shown')) {
            $('.container-product-menu-overflow').removeClass('shown');
            $('.container-product-menu-overflow').stop().fadeOut(1000);
        } else {
            $('.container-product-menu-overflow').addClass('shown');
            $('.container-product-menu-overflow').stop().fadeIn(1000);
        }

    });
	$('.container-product-menu li.level1 a,.container-product-menu li.level2 a,.container-product-menu li.level3 a,.container-product-menu li.level4 a').click(function () {
		 if ($('.container-product-menu-overflow').hasClass('shown')) {
            $('.container-product-menu-overflow').removeClass('shown');
        } else {

        }
		
    });
    /*$('.container-product-menu li.maximenuck').mouseover(function(){
     $('.container-product-menu-overflow').hide(400);
     console.log('click2');
     });*/
    /*right menu*/

    /*background-image product page*/
    function height_bg() {
        var height_sb = $('.uk-article-startblock').outerHeight(),
                height_bc = $('.uk-breadcrumb').outerHeight(),
                height_sbbg = height_sb + height_bc + 100;
        height_sbbgblog = height_sb + height_bc + 50;
        $('.uk-article-startblock-bg').css('height', height_sbbg);
        $('.uk-article-startblock-blog-inner-bg').css('height', height_sbbgblog);
    }
    function height_bg_mob() {
        var height_sb = $('.uk-article-startblock').outerHeight(),
                height_sbbg = height_sb + 30;
        height_sbbgblog = height_sb;
        $('.uk-article-startblock-bg').css('height', height_sbbg);
        $('.uk-article-startblock-blog-inner-bg').css('height', height_sbbgblog);
    }
    if ($(window).width() > 769) {
        height_bg();
    } else {
        height_bg_mob();
    }
    $(window).resize(function () {
        if ($(window).width() > 769) {
            height_bg();
        } else {
            height_bg_mob();
        }
    });
    var sbbg = $('.uk-article-startblock-bg').length;
    if (sbbg > 0) {
        $('.uk-breadcrumb').addClass('opacity-80');
    }
    var sbbg = $('.uk-article-startblock-blog-inner-bg').length;
    if (sbbg > 0) {
        $('.uk-breadcrumb').addClass('blue-breadcrumbs');
    }

    /*product inner page height of elements*/
    $.fn.equivalent = function () {
        var $blocks = $(this),
                maxH = $blocks.eq(0).outerHeight();
        $blocks.each(function () {
            maxH = ($(this).height() > maxH) ? $(this).outerHeight() : maxH;
        });
        $blocks.outerHeight(maxH);
        $blocks.css('line-height', maxH + 'px');
    }

    $('.classes-item-img').equivalent();



    /*start right menu open submenu if parent is active*/
    $('.right-menu .mobilemaximenuck .mobilemaximenuckitem .maximenuck.parent.level1').each(function () {
        if ($(this).hasClass('active')) {
            $(this).addClass('open');
            $(this).parent().find('.mobilemaximenucksubmenu').css('display', 'block');
        }
    });
    $('.right-menu .mobilemaximenuck .mobilemaximenuckitem .maximenuck.parent.level1 .mobilemaximenucktogglericon').click(function () {
        if ($(this).parent().hasClass('open')) {
            $('.right-menu .mobilemaximenuck .mobilemaximenuckitem .maximenuck.parent.level1').removeClass('open');
            $('.mobilemaximenucksubmenu').hide();
            $('.right-menu .mobilemaximenuck .mobilemaximenuckitem .maximenuck.parent.level1.active').addClass('open');
            $('.right-menu .mobilemaximenuck .mobilemaximenuckitem .maximenuck.parent.level1.active').siblings('.mobilemaximenucksubmenu').css('display', 'block');
            $(this).parent().addClass('open');
            $(this).parent().siblings('.mobilemaximenucksubmenu').slideDown();
        } else {
            $(this).parent().removeClass('open');
            $(this).parent().siblings('.mobilemaximenucksubmenu').slideUp();
            $('.right-menu .mobilemaximenuck .mobilemaximenuckitem .maximenuck.parent.level1.active').addClass('open');
            $('.right-menu .mobilemaximenuck .mobilemaximenuckitem .maximenuck.parent.level1.active').siblings('.mobilemaximenucksubmenu').css('display', 'block');
        }
    });


    function rightMenuScroll() {
        $(window).scroll(function () {
            var rightMenu = $('aside').outerHeight(),
                    rightMenuWrap = $('.aside-wrap').outerHeight(),
					headerHeight = $('header').outerHeight(),
                    heightWindow = $(window).outerHeight() - headerHeight,
                    heightDoc = $(document).outerHeight(),
                    heightFooter = $('.footer').outerHeight();
            scrollHeight = heightDoc - rightMenuWrap - heightFooter - headerHeight;
            scrollingTop = parseInt($(window).scrollTop());

            if (scrollingTop < rightMenuWrap) {
                $('.aside-wrap').removeClass('no-fixed');
                $('.aside-wrap').removeClass('fixed-bottom');
                $('.aside-wrap').removeClass('fixed-top');

            } else if (scrollingTop > rightMenuWrap && scrollingTop < scrollHeight) {
                $('.aside-wrap').removeClass('no-fixed');
                $('.aside-wrap').removeClass('fixed-bottom');
                $('.aside-wrap').addClass('fixed-top');

            } else if (scrollingTop > scrollHeight) {
                $('.aside-wrap').removeClass('fixed-bottom');
                $('.aside-wrap').removeClass('fixed-top');
                $('.aside-wrap').addClass('no-fixed');

            } else {
                $('.aside-wrap').removeClass('fixed-bottom');
                $('.aside-wrap').removeClass('fixed-top');
                $('.aside-wrap').removeClass('no-fixed');

            }
        });
    }
    ;
	function rightMenuNoScroll() {
        $(window).scroll(function () {
            var rightMenu = $('aside').outerHeight(),
                    rightMenuWrap = $('.aside-wrap').outerHeight(),
					headerHeight = $('header').outerHeight(),
                    heightWindow = $(window).outerHeight() - headerHeight,
                    heightDoc = $(document).outerHeight(),
                    heightFooter = $('.footer').outerHeight();
            scrollHeight = heightDoc - rightMenuWrap - heightFooter - headerHeight;
            scrollingTop = parseInt($(window).scrollTop());

            if (scrollingTop < rightMenuWrap) {
                $('.aside-wrap').removeClass('no-fixed');
                $('.aside-wrap').removeClass('fixed-bottom');
                $('.aside-wrap').removeClass('fixed-top');

            } else if (scrollingTop > rightMenuWrap && scrollingTop < scrollHeight) {
                $('.aside-wrap').removeClass('no-fixed');
                $('.aside-wrap').removeClass('fixed-bottom');
                $('.aside-wrap').removeClass('fixed-top');

            } else if (scrollingTop > scrollHeight) {
                $('.aside-wrap').removeClass('fixed-bottom');
                $('.aside-wrap').removeClass('fixed-top');
                $('.aside-wrap').removeClass('no-fixed');

            } else {
                $('.aside-wrap').removeClass('fixed-bottom');
                $('.aside-wrap').removeClass('fixed-top');
                $('.aside-wrap').removeClass('no-fixed');

            }
        });
    }
    ;

    if ($(window).width() > 769) {
        rightMenuScroll();
    } else{
		rightMenuNoScroll();
    }
    $(window).resize(function () {
        if ($(window).width() > 769) {
            rightMenuScroll();
        } else{
			rightMenuNoScroll();
		}
    });
    /*finish right menu open submenu if parent is active*/

    /*focus textarea*/
    $('.scroll-textarea textarea').focusin(function () {
        $('.scroll-textarea').addClass('focus');
    })
    $('.scroll-textarea textarea').focusout(function () {
        $('.scroll-textarea').removeClass('focus');
    })

    /*posters page height of elements*/
    $.fn.posterequivalent = function () {
        var $blocks = $(this),
                maxH = $blocks.eq(0).outerHeight();
        $blocks.each(function () {
            maxH = ($(this).height() > maxH) ? $(this).outerHeight() : maxH;
        });
        $blocks.outerHeight(maxH);
        $blocks.css('height', maxH + 'px');
    }

    $('.posters-item-wrap-block-inner img').posterequivalent();
    /*������ Scroll to Top*/
    jQuery(window).scroll(function(){
		if (jQuery(this).scrollTop() > 150) {
			jQuery('.scrolltop').show();
		} else {
			jQuery('.scrolltop').hide();
		}
	});

    jQuery('.scrolltop').click(function(){
		jQuery('html, body').animate({scrollTop:'0'}, 500);
		return false;
	});



    jQuery('.menuright-menu li.parent.level1.active').each(function () {
        $(this).addClass('open');
    });

    jQuery('.menuright-menu li.parent.level1').click(function () {
        var obj = jQuery(this);
        if (obj.hasClass('open')) {
            obj.removeClass('open');
            $('.menuright-menu li.parent.level1').removeClass('open');
            $('.menuright-menu li.parent.level1 ul').slideUp();
            obj.find('ul').stop().slideUp();
        }else {
            $('.menuright-menu li.parent.level1').removeClass('open');
            $('.menuright-menu li.parent.level1 ul').slideUp();
            obj.addClass('open');
            obj.find('ul').stop().slideDown();
        }

    });

    function asideHeight(){
        var aside_h = $('.aside-wrap-block').outerHeight(),
            tm_main_h = $('.tm-main').outerHeight();
            window_h = $(window).outerHeight(),
            footer_h = $('footer').outerHeight(),
            header_h = $('header').outerHeight(),
            main_h = window_h - footer_h - header_h;

        if(aside_h < main_h){
            $('.aside-wrap-block').css('min-height',main_h);
			console.log('tm_main_h < aside_h');
        }
    }
    asideHeight();

});
